<template>
  <div>
    <div class="d-flex flex-row flex-column-fluid page">
      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
        <Nav selected="settings"></Nav>
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <div class="d-flex flex-column-fluid">
            <div class="container">
              <div v-if="error.length > 0" class="alert alert-danger">
                <b>Problem</b>: {{error}}.
              </div>
              <div class="row">
                <div class="col-xxl-8">
                  <div class="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0 pt-6">
                      <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder font-size-h4 text-dark-75">Edit Property</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-lg">Make changes to this property.</span>
                      </h3>
                    </div>
                    <div class="card-body pt-5">

                      <EditPropertyForm v-if="loaded" :property="property" v-on:save="saveProperty"></EditPropertyForm>
                    </div>
                  </div>
                </div>
                <div class="col-xxl-4">
                  <EditGuide></EditGuide>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
 import Nav from '../components/Nav.vue'
 import Footer from '../components/Footer.vue'
 import { fetchJSON } from '../auth'
 import EditPropertyForm from '../components/EditPropertyForm.vue'
 import EditGuide from '../components/EditGuide.vue'

 export default {
   name: 'EditProperty',
   components: { Nav, EditPropertyForm, Footer, EditGuide },

   data() {
     return {
       error: '',
       property: {},
       loaded: false,
     }
   },

   methods: {
     saveProperty(property) {
       fetchJSON(`/properties/${property.id}`, {
         method: 'PUT',
         body: JSON.stringify({property: property})
       }).then(() => {
         this.$router.push({ name: 'Properties' })
       }).catch(error => this.error = error)
     },
     loadProperty() {
       const id = this.$route.params.id
       fetchJSON(`/properties/${id}`, {
         method: 'GET',
       }).then(data => {
         this.property = data.property
         this.loaded = true
       }).catch(error => this.error = error)
     }
   },

   mounted() {
     this.loadProperty()
   }
 }
</script>

<style lang="scss">
</style>
