<template>
  <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
    <div class="col">
      <div class="card card-stretch mb-4" v-bind:class="{'selected': currentPlan == 'basic'}">
        <div class="card-header">
          <h4 class="my-0 fw-normal">Basic</h4>
        </div>
        <div class="card-body card-stretch">
          <h1 class="card-title pricing-card-title">$6.99 <small class="text-muted">/ mo</small></h1>
          <ul class="list-unstyled mt-3 mb-4">
            <li>1 property</li>
            <li>Biweekly evaluations</li>
            <li>Simplified reports</li>
            <li>Basic support</li>
          </ul>
          <button @click="select('basic')" type="button" class="w-100 btn btn-lg" v-bind:class="{'btn-outline-primary': currentPlan != 'basic', 'btn-primary': currentPlan == 'basic'}">{{ basicButtonText }}</button>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card card-stretch mb-4" v-bind:class="{'selected': currentPlan == 'pro'}">
        <div class="card-header">
          <h4 class="my-0 fw-normal">Pro</h4>
        </div>
        <div class="card-body">
          <h1 class="card-title pricing-card-title">$15.99 <small class="text-muted">/ mo</small></h1>
          <ul class="list-unstyled mt-3 mb-4">
            <li>5 properties</li>
            <li>Weekly evaluations</li>
            <li>Advanced reports</li>
            <li>Priority support</li>
          </ul>
          <button @click="select('pro')" type="button" class="w-100 btn btn-lg" v-bind:class="{'btn-outline-primary': currentPlan != 'pro', 'btn-primary': currentPlan == 'pro'}">{{ proButtonText }}</button>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card card-stretch mb-4">
        <div class="card-header">
          <h4 class="my-0 fw-normal">Unlimited</h4>
        </div>
        <div class="card-body">
          <h2 class="card-title pricing-card-title"><router-link to="/contact">Contact Us</router-link></h2>
          <ul class="list-unstyled mt-3 mb-4">
            <li>Unlimited properties</li>
            <li>On-demand evaluations</li>
            <li>Advanced reports</li>
            <li>Priority support</li>
          </ul>
          <router-link to="/contact" type="button" class="w-100 btn btn-lg btn-outline-secondary">Contact us</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 export default {
   name: 'Pricing',
   props: ['current'],
   data() {
     return {
       currentPlan: this.current,
     }
   },
   methods: {
     select(plan) {
       this.currentPlan = plan
       this.$emit('select', plan)
     }
   },
   computed: {
     basicButtonText() {
       return this.currentPlan == 'basic' ? 'Selected' : 'Select';
     },
     proButtonText() {
       return this.currentPlan == 'pro' ? 'Selected' : 'Select';
     },
   }
 }
</script>

<style scoped>
 .card-header {
   background-color: #f6f6f6;
 }
 .selected {
   border-color: #0BB783;
 }
</style>
