<template>
  <div class="login-footer py-10 flex-column-auto">
    <div class="container d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between">
      <div class="font-size-h6 font-weight-bolder order-2 order-md-1 py-2 py-md-0">
        <span class="text-muted font-weight-bold mr-2">© 2021</span>
        <router-link to="/" class="text-dark-50 text-hover-primary">HomeInsights.ca</router-link>
      </div>
      <div class="font-size-h5 font-weight-bolder order-1 order-md-2 py-2 py-md-0">
        <router-link to="/terms-of-use" class="text-primary">Terms of Use</router-link>
        <router-link to="/privacy-policy" class="text-primary ml-10">Privacy Policy</router-link>
        <router-link to="/contact" class="text-primary ml-10">Contact Us</router-link>
      </div>
    </div>
  </div>
</template>

<script>
 export default {
   name: 'LoginFooter'
 }
</script>
