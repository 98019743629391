<template>
  <div>
    <div class="d-flex flex-row flex-column-fluid page">
      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
        <Nav selected="settings"></Nav>
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <div class="d-flex flex-column-fluid">
            <div class="container">
              <div v-if="error.length > 0" class="alert alert-danger">
                <b>Problem</b>: {{error}}.
              </div>
              <div v-if="error.length == 0" class="alert alert-success">
                Please note that our service is currently only available in <b>BC, Canada</b>. We will be adding support for all provinces very soon.
              </div>
              <div class="row">
                <div class="col-xl-3 col-xxl-3">
                  <SettingsNav selected="payment"></SettingsNav>
                </div>
                <div class="col-xl-9 col-xxl-9">

                  <div class="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0 pt-6">
                      <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder font-size-h4 text-dark-75">Subscription</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-lg">Add or update your payment information.</span>
                      </h3>

                    </div>
                    <div class="card-body pt-5">

                      <div class="mt-5 mb-10">
                        <Pricing v-if="planLoaded" :current="this.plan" v-on:select="setPlan"></Pricing>
                      </div>

                      <p v-if="useCurrentCard">Using your existing credit card ending with <b>{{last4}}</b>. <a @click.prevent="showCard" href="#">Change card</a>.</p>

                      <div v-bind:class="{'hide': useCurrentCard}">
                        <p>Please enter your payment information. Our secure payment system is handled by <a href="https://stripe.com">Stripe</a>.</p>
                        <div class="card shadow-none border mb-5 card-wrapper">
                          <div id="card-element" class="m-5"></div>
                        </div>
                      </div>

                      <button type="button" @click.prevent="submit" class="btn btn-primary mt-2">Update Subscription</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
 import Nav from '../components/Nav.vue'
 import SettingsNav from '../components/SettingsNav.vue'
 import Footer from '../components/Footer.vue'
 import Pricing from '../components/Pricing.vue'
 import { fetchJSON, getSession, getUser } from '../auth'

 let stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)

 export default {
   name: 'PaymentDetails',
   components: { Nav, SettingsNav, Footer, Pricing },

   data() {
     return {
       error: '',
       success: false,
       stripeCard: null,
       last4: '',
       plan: '',
       planLoaded: false,
       useCurrentCard: true,
       paymentMethodId: '',
     }
   },

   methods: {
     showCard() {
       this.useCurrentCard = false
     },

     setPlan(plan) {
       this.plan = plan
     },

     loadSubscription() {
       fetchJSON('/subscription').then(data => {
         this.last4 = data.payment_details.stripe_payment_method_last4
         this.plan = data.payment_details.product_name
         this.useCurrentCard = this.last4 != ""
         this.paymentMethodId = data.payment_details.stripe_payment_method_id
         this.planLoaded = true
       }).catch(error => this.error = error)
     },

     submit() {
       const user = getUser()

       if (this.useCurrentCard) {
         this.savePaymentDetails()
       } else {

         stripe.createPaymentMethod({
           type: 'card',
           card: this.stripeCard,
           billing_details: {
             name: user.name,
           },
         }).then((result) => {
           if (result.error) {
             this.error = result.error.message
             return
           }

           this.paymentMethodId = result.paymentMethod.id
           this.last4 = result.paymentMethod.last4

           this.savePaymentDetails()
         })
       }
     },

     savePaymentDetails() {
       const session = getSession()

       const prices = {
         basic: process.env.VUE_APP_STRIPE_PRICE_BASIC,
         pro: process.env.VUE_APP_STRIPE_PRICE_PRO,
       }

       const paymentDetails = {
         user_id: session.user_id,
         stripe_payment_method_id: this.paymentMethodId,
         stripe_price_id: prices[this.plan],
         stripe_payment_method_last4: this.last4,
         product_name: this.plan,
       }

       fetchJSON('/subscription', {
         method: 'PUT',
         body: JSON.stringify(paymentDetails),
       }).then(() => {
         window.heap.track('subscribe')
         this.$router.push({ name: 'Properties' })
       }).catch(error => {
         this.error = error
       })
     },
   },

   mounted() {
     this.loadSubscription()

     let elements = stripe.elements()

     this.stripeCard = elements.create('card', {})
     this.stripeCard.mount('#card-element')
     this.stripeCard.on('change', (event) => {
       if (event.error) {
         this.error = event.error.message
       } else {
         this.error = ''
       }
     })
   }
 }
</script>

<style lang="scss">
 .card-wrapper {
   max-width: 550px;
 }
 .hide {
   display: none;
 }
</style>
