<template>
  <div class="login login-2 login-signin-on d-flex flex-column flex-column-fluid bg-white position-relative overflow-hidden" id="kt_login">
    <LoginHeader page="register"></LoginHeader>

    <div class="login-body d-flex flex-column-fluid align-items-stretch justify-content-center">

      <div class="container row">
        <div class="col-12">
          <div v-if="error.length > 0" class="alert alert-danger">
            <b>Problem</b>: {{error}}.
          </div>
          <div v-if="success" class="alert alert-success">
            Thanks! We'll be in touch shortly.
          </div>
          <div class="card card-custom gutter-b">
            <div class="card-header border-0 pt-6">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder font-size-h4 text-dark-75">Contact Us</span>
                <span class="text-muted mt-3 font-weight-bold font-size-lg">We're happy to hear from you.</span>
              </h3>
            </div>
            <div class="card-body pt-3">
              <form @submit.prevent="submit">
                <div class="form-group row">
                  <label for="address" class="col-lg-2 col-form-label">Name</label>
                  <div class="col-lg-10">
                    <input id="address" required v-model="name" class="form-control" type="text">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="email" class="col-lg-2 col-form-label">Email Address</label>
                  <div class="col-lg-10">
                    <input id="email" required v-model="email" class="form-control" type="email">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-lg-2 col-form-label">Subject</label>
                  <div class="col-lg-10">
                    <input v-model="subject" required class="form-control" type="text">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-lg-2 col-form-label">Message</label>
                  <div class="col-lg-10">
                    <textarea rows="10" class="form-control" v-model="message" id="message"></textarea>
                  </div>
                </div>

                <div class="mt-6">
                  <button type="submit" class="btn btn-primary">Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <LoginFooter></LoginFooter>

  </div>
</template>

<script>
 import '../keen/sass/pages/login/login-2.scss'
 import LoginHeader from '../components/LoginHeader.vue'
 import LoginFooter from '../components/LoginFooter.vue'
 import { fetchJSON } from '../auth'

 export default {
   name: 'Contact',
   components: { LoginHeader, LoginFooter },
   data() {
     return {
       error: '',
       success: false,
       name: '',
       email: '',
       subject: '',
       message: '',
     }
   },
   methods: {
     submit() {
       const message = {
         name: this.name,
         email: this.email,
         subject: this.subject,
         message: this.message,
       }
       fetchJSON('/contact', {
         method: 'POST',
         body: JSON.stringify({ message: message })
       }).then(() => {
         this.success = true
       }).catch(error => this.error = error)
     },
   },
 }
</script>

<style scoped>
 .card.card-custom {
   box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 10%);
 }
 .card-body p {
   line-height: 1.8rem;
   font-size: 1.1rem;
 }
</style>
