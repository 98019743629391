<template>
  <div class="login-header py-10 flex-column-auto">
    <div class="container d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between">
      <a href="#" class="flex-column-auto py-5 py-md-0">
        <img src="@/assets/media/logos/hi-logo-sm.png" alt="logo" height="90" />
      </a>
      <span v-if="page == 'register'" class="text-muted font-weight-bold font-size-h4">Already a member?
        <router-link to="/login" id="kt_login_signup" class="text-primary font-weight-bolder">Login</router-link>
      </span>
      <span v-if="page == 'login'" class="text-muted font-weight-bold font-size-h4">Don't have an account?
        <router-link to="/register" id="kt_login_signup" class="text-primary font-weight-bolder">Sign up</router-link>
      </span>
    </div>
  </div>
</template>

<script>
 export default {
   name: 'LoginHeader',
   props: ['page'],
 }
</script>
