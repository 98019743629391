<template>
  <div class="login login-2 login-signin-on d-flex flex-column flex-column-fluid bg-white position-relative overflow-hidden" id="kt_login">
    <LoginHeader page="login"></LoginHeader>
    <div class="login-body d-flex flex-column-fluid align-items-stretch justify-content-center">
      <div class="container row">
        <div class="col-lg-6 d-flex align-items-center">
          <div class="login-form login-signin">
            <form @submit.prevent="submit" class="form w-xxl-550px rounded-lg p-20" id="kt_login_signin_form">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Welcome back</h3>
              </div>
              <div v-if="error.length > 0" class="alert alert-danger">
                <b>Problem</b>: {{error}}.
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">Email</label>
                <input required v-model="email" class="form-control form-control-solid h-auto p-6 rounded-lg" type="email" name="username" autocomplete="off" />
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5">Password</label>
                  <router-link to="/forgot" class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5" id="kt_login_forgot">Forgot Password?</router-link>
                </div>
                <input required v-model="password" class="form-control form-control-solid h-auto p-6 rounded-lg" type="password" name="password" autocomplete="off" />
              </div>
              <div class="pb-lg-0 pb-5">
                <button type="submit" class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3">Sign In</button>
                <button id="googleSignIn" type="button" class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg">
                  <span class="svg-icon svg-icon-md">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M19.9895 10.1871C19.9895 9.36767 19.9214 8.76973 19.7742 8.14966H10.1992V11.848H15.8195C15.7062 12.7671 15.0943 14.1512 13.7346 15.0813L13.7155 15.2051L16.7429 17.4969L16.9527 17.5174C18.879 15.7789 19.9895 13.221 19.9895 10.1871Z" fill="#4285F4" />
                      <path d="M10.1993 19.9313C12.9527 19.9313 15.2643 19.0454 16.9527 17.5174L13.7346 15.0813C12.8734 15.6682 11.7176 16.0779 10.1993 16.0779C7.50243 16.0779 5.21352 14.3395 4.39759 11.9366L4.27799 11.9466L1.13003 14.3273L1.08887 14.4391C2.76588 17.6945 6.21061 19.9313 10.1993 19.9313Z" fill="#34A853" />
                      <path d="M4.39748 11.9366C4.18219 11.3166 4.05759 10.6521 4.05759 9.96565C4.05759 9.27909 4.18219 8.61473 4.38615 7.99466L4.38045 7.8626L1.19304 5.44366L1.08875 5.49214C0.397576 6.84305 0.000976562 8.36008 0.000976562 9.96565C0.000976562 11.5712 0.397576 13.0882 1.08875 14.4391L4.39748 11.9366Z" fill="#FBBC05" />
                      <path d="M10.1993 3.85336C12.1142 3.85336 13.406 4.66168 14.1425 5.33717L17.0207 2.59107C15.253 0.985496 12.9527 0 10.1993 0C6.2106 0 2.76588 2.23672 1.08887 5.49214L4.38626 7.99466C5.21352 5.59183 7.50242 3.85336 10.1993 3.85336Z" fill="#EB4335" />
                    </svg>
                  </span>Sign in with Google</button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-6 bgi-size-contain bgi-no-repeat bgi-position-y-center bgi-position-x-center min-h-150px mt-10 m-md-0" :style="{backgroundImage: 'url(' + require('@/assets/media/svg/illustrations/accomplishment.svg') + ')'}"></div>
      </div>
    </div>
    <LoginFooter></LoginFooter>
  </div>
</template>

<script>
 import '../keen/sass/pages/login/login-2.scss'
 import { fetchJSON } from '../auth'
 import LoginHeader from '../components/LoginHeader.vue'
 import LoginFooter from '../components/LoginFooter.vue'

 export default {
   components: { LoginHeader, LoginFooter },

   data() {
     return {
       email: '',
       password: '',
       error: '',
     }
   },

   mounted() {
     let that = this
     this.$nextTick(function () {
       let element = document.getElementById('googleSignIn');
       window.gapi.load('auth2', function(){
         let auth2 = window.gapi.auth2.init({
           client_id: '67499962843-02cha5gh04riab20mmjeu1k7732n66pm.apps.googleusercontent.com',
           cookiepolicy: 'single_host_origin',
           fetch_basic_profile: true
         });
         auth2.attachClickHandler(
           element, {},
           function(googleUser) {
             var token = googleUser.getAuthResponse().id_token;
             fetchJSON('/sessions/google', {
               method: 'POST',
               body: JSON.stringify({token: token}),
             }).then((data) => {
               that.loginComplete(data.session)
             }).catch(error => that.error = error)
           }, function(error) {
             console.log('Sign-in error', error);
           }
         );
       });
     })
   },

   methods: {
     loginComplete(session) {
       localStorage.setItem('session', JSON.stringify(session))
       fetchJSON('/users/me').then(data => {
         localStorage.setItem('user', JSON.stringify(data.user))
         window.heap.identify(data.user.id)
         window.heap.track('google-login')
         window.heap.track('login')
         this.$router.push('/properties')
       }).catch(error => this.error = error)
     },

     submit() {
       fetchJSON('/sessions', {
         method: 'POST',
         body: JSON.stringify({email: this.email, password: this.password })
       }).then(data => {
         this.loginComplete(data)
       }).catch(error => this.error = error)
     }
   }
 }
</script>
