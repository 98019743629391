import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Forgot from '../views/Forgot.vue'
import Properties from '../views/Properties.vue'
import AddProperty from '../views/AddProperty.vue'
import EditProperty from '../views/EditProperty.vue'
import AccountSettings from '../views/AccountSettings.vue'
import PaymentDetails from '../views/PaymentDetails.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsOfUse from '../views/TermsOfUse.vue'
import ConfirmEmail from '../views/ConfirmEmail.vue'
import Contact from '../views/Contact.vue'
import { fetchJSON, getSession } from '../auth.js'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { anonOnly: true },
  },
  {
    path: '/',
    name: 'Home',
    component: Login,
    meta: { anonOnly: true },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { anonOnly: true },
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: Forgot,
    meta: { anonOnly: true },
  },
  {
    path: '/confirm-email',
    name: 'Confirm Email',
    component: ConfirmEmail,
    meta: { authRequired: true },
  },
  {
    path: '/properties',
    name: 'Properties',
    component: Properties,
    meta: { authRequired: true },
  },
  {
    path: '/properties/new',
    name: 'Add Property',
    component: AddProperty,
    meta: { authRequired: true },
  },
  {
    path: '/properties/:id/edit',
    name: 'Edit Property',
    component: EditProperty,
    meta: { authRequired: true },
  },
  {
    path: '/settings/payment',
    name: 'Payment Details',
    component: PaymentDetails,
    meta: { authRequired: true },
  },
  {
    path: '/settings',
    name: 'Account Settings',
    component: AccountSettings,
    meta: { authRequired: true },
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPolicy,
    meta: { authRequired: false },
  },
  {
    path: '/terms-of-use',
    name: 'Terms of Use',
    component: TermsOfUse,
    meta: { authRequired: false },
  },
  {
    path: '/contact',
    name: 'Contact Us',
    component: Contact,
    meta: { authRequired: false },
  },
]

function checkAuth(fn) {
  const session = getSession()
  if (!session) {
    return fn()
  }

  fetchJSON('/users/me').then(data => {
    fn(session, data.user)
  }).catch(() => {
    localStorage.removeItem('session')
    localStorage.removeItem('user')
    fn()
  })
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  checkAuth((session, user) => {
    // Page requires login and user not logged in
    if (to.meta.authRequired && !(session && user)) {
      return next('/login')
    }
    // Login or register page but user is logged in
    if (to.meta.anonOnly && session && user) {
      return next('/properties')
    }
    // Email confirmation page
    if (to.meta.authRequired
        && to.name != 'Confirm Email'
        && to.name != 'Logout'
        && user
        && !user.confirmed) {
      return next('/confirm-email')
    }
    next()
  })
})

router.afterEach((to) => {
  let title = to.name + ' - HomeInsights.ca'
  document.title = title
})

export default router
