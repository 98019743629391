<template>
  <form @submit.prevent="submit">
    <div class="form-group row">
      <label class="col-xl-2 col-form-label">Property Type</label>
      <div class="col-xl-10">
        <select required v-model="property_type" class="form-control">
          <option value="" selected>Choose</option>
          <option value="condo_half_duplex">Condo - 1/2 Duplex</option>
          <option value="condo">Condo - Apartment/Condo</option>
          <option value="condo_row_house">Condo - Row House (Non-Strata)</option>
          <option value="condo_townhouse">Condo - Townhouse</option>
          <option value="house_duplex">House - Duplex</option>
          <option value="house_fourplex">House - Fourplex</option>
          <option value="house_with_acreage">House - House with Acreage</option>
          <option value="house_single_family">House - Single Family</option>
          <option value="house_manufactured">House - Manufactured</option>
          <option value="house_man_with_land">House - Manufactured with Land</option>
          <option value="house_recreational">House - Recreational</option>
          <option value="house_townhouse">House - Townhouse</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-form-label">Address</label>
      <div class="col-xl-10">
        <input id="address" autocomplete="off" placeholder="123 Nowhere Lane" required v-model="address" class="form-control" type="text">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-form-label">Unit</label>
      <div class="col-xl-10">
        <input placeholder="321" v-model="unit" class="form-control" type="text">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-form-label">City</label>
      <div class="col-xl-10">
        <input placeholder="Vancouver" v-model="city" required class="form-control" type="text">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-form-label">Province</label>
      <div class="col-xl-10">
        <select required v-model="province" class="form-control">
          <option value="" disabled selected>Choose</option>
          <option value="AB">Alberta</option>
          <option value="BC">BC</option>
          <option value="MB">Manitoba</option>
          <option value="NB">New Brunswick</option>
          <option value="NL">Newfoundland and Labrador</option>
          <option value="NT">Northwest Territories</option>
          <option value="NS">Nova Scotia</option>
          <option value="NU">Nunavut</option>
          <option value="ON">Ontario</option>
          <option value="PE">Prince Edward Island</option>
          <option value="QC">Quebec</option>
          <option value="SK">Saskatchewan</option>
          <option value="YT">Yukon</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-form-label">Postal Code</label>
      <div class="col-xl-10">
        <input placeholder="V3G 2X9" required v-model="postal_code" class="form-control" type="text">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-form-label">Bedrooms</label>
      <div class="col-xl-10">
        <input type="number" v-model.number="bedrooms" required class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-form-label">Bathrooms</label>
      <div class="col-xl-10">
        <input type="number" required v-model.number="bathrooms" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-form-label">Square Footage</label>
      <div class="col-xl-10">
        <input type="number" required v-model.number="area" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-form-label">Year Built</label>
      <div class="col-xl-10">
        <input type="number" required v-model.number="year_built" placeholder="1999" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-form-label">Land Size</label>
      <div class="col-xl-10">
        <input type="number" required v-model.number="land_area" class="form-control">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-form-label">Features</label>
      <div class="col-xl-10">
        <div class="checkbox-inline mt-2">
          <label class="checkbox checkbox-success">
            <input v-model="has_pool" type="checkbox">
            <span></span>Pool</label>
          <label class="checkbox checkbox-success">
            <input v-model="has_garage" type="checkbox">
            <span></span>Garage</label>
          <label class="checkbox checkbox-success">
            <input v-model="has_rental_unit" type="checkbox">
            <span></span>Rental Unit</label>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="quality" class="col-xl-2 col-form-label">Quality</label>
      <div class="col-6">
        <input class="form-control" type="range" v-model="quality" />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-form-label">Picture</label>
      <div class="col-xl-10">
        <div id="file-upload" class="dropzone"></div>
      </div>
    </div>
    <div class="mt-2">
      <button type="submit" :disabled="!canSubmit" class="btn btn-primary mr-2">Save</button>
      <button type="button" @click="cancel" class="btn btn-secondary">Cancel</button>
    </div>
  </form>
</template>

<script>
 import { getSession } from '../auth'
 import Dropzone from 'dropzone'

 Dropzone.autoDiscover = false

 export default {
   name: 'EditPropertyForm',
   props: {
     property: Object,
   },
   data() {
     return {
       id: this.property.id,
       property_type: this.property.property_type,
       google_place_id: this.property.google_place_id,
       unit: this.property.unit,
       address: this.property.address,
       city: this.property.city,
       postal_code: this.property.postal_code,
       province: this.property.province,
       latitude: this.property.latitude,
       longitude: this.property.longitude,
       image_url: this.property.image_url,
       bedrooms: this.property.bedrooms,
       bathrooms: this.property.bathrooms,
       has_pool: this.property.has_pool,
       has_rental_unit: this.property.has_rental_unit,
       has_garage: this.property.has_garage,
       area: this.property.area,
       land_area: this.property.land_area,
       year_built: this.property.year_built,
       quality: this.property.quality * 100,
       canSubmit: true,
     }
   },
   methods: {
     submit() {
       let prop = {
         id: this.id,
         property_type: this.property_type,
         google_place_id: this.google_place_id,
         address: this.address,
         unit: this.unit,
         city: this.city,
         postal_code: this.postal_code,
         province: this.province,
         latitude: this.latitude,
         longitude: this.longitude,
         image_url: this.image_url,
         bedrooms: this.bedrooms,
         bathrooms: this.bathrooms,
         has_pool: this.has_pool,
         has_rental_unit: this.has_rental_unit,
         has_garage: this.has_garage,
         area: this.area,
         land_area: this.land_area,
         year_built: this.year_built,
         quality: parseInt(this.quality) / 100,
       }
       this.$emit('save', prop)
     },
     cancel() {
       this.$router.push({ name: 'Properties' })
     }
   },
   mounted() {
     var address = document.getElementById('address');
     var complete = new window.google.maps.places.Autocomplete(address, {types: ['geocode']});

     complete.setFields(['address_component', 'place_id', 'geometry']);

     complete.addListener('place_changed', () => {
       const place = complete.getPlace();
       const components = place.address_components

       this.google_place_id = place.place_id;
       this.address = components[0].long_name + ' ' + components[1].short_name
       this.city = components[2].long_name
       this.postal_code = components[6].long_name
       this.province = components[4].short_name
       this.latitude = place.geometry.location.lat()
       this.longitude = place.geometry.location.lng()
     });

     const session = getSession()

     var dz = new Dropzone('#file-upload', {
       url: `${process.env.VUE_APP_API_URL}/uploads`,
       headers: { 'Auth-Token': session.token },
       acceptedFiles: 'image/jpeg,image/png'
     })

     const el = document.querySelector('#file-upload button')
     el.classList.add('btn')
     el.classList.add('btn-outline-secondary')
     el.innerText = 'Select an image file'

     dz.on('addedfile', () => {
       this.canSubmit = false
     })

     dz.on('success', (file, response) => {
       const data = JSON.parse(response)
       this.image_url = data.path
       this.canSubmit = true
     })
   }
 }
</script>

<style lang="scss">
 .dz-image {
   margin: 10px;
 }
 .dz-details {
   margin-left: 10px;
 }
 .dz-progress {
   display: none;
 }
 .dz-success-mark {
   display: none;
 }
 .dz-error-mark {
   display: none;
 }
</style>
