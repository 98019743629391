<template>
  <div class="login login-2 login-signin-on d-flex flex-column flex-column-fluid bg-white position-relative overflow-hidden" id="kt_login">
    <LoginHeader page="register"></LoginHeader>
    <div class="login-body d-flex flex-column-fluid align-items-stretch justify-content-center">
      <div class="container row">
        <div class="col-lg-6 d-flex align-items-center">
          <div class="login-form login-signup">
            <form @submit.prevent="submit" class="form w-xxl-550px rounded-lg p-20" id="kt_login_signup_form">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Create a free account</h3>
                <p class="text-muted font-weight-bold font-size-h4">Sign up to manage & track your properties</p>
              </div>
              <div v-if="error.length > 0" class="alert alert-danger">
                <b>Problem</b>: {{error}}.
              </div>
              <div class="form-group">
                <input v-model="name" class="form-control form-control-solid h-auto p-6 rounded-lg font-size-h6" type="text" placeholder="Fullname" name="fullname" autocomplete="off" required />
              </div>
              <div class="form-group">
                <input v-model="email" class="form-control form-control-solid h-auto p-6 rounded-lg font-size-h6" type="email" placeholder="Email" name="email" autocomplete="off" required />
              </div>
              <div class="form-group">
                <input v-model="password" class="form-control form-control-solid h-auto p-6 rounded-lg font-size-h6" type="password" placeholder="Password" name="password" autocomplete="off" required />
              </div>
              <div class="form-group">
                <input v-model="confirm" class="form-control form-control-solid h-auto p-6 rounded-lg font-size-h6" type="password" placeholder="Confirm password" name="cpassword" autocomplete="off" required />
              </div>
              <div class="form-group">
                <div class="checkbox-inline">
                  <label class="checkbox">
                    <input type="checkbox" name="agree" />
                    <span></span>I agree to the <a href="/terms-of-use" target="new" class="ml-1">Terms of Use</a>&nbsp;and&nbsp;<a href="/privacy-policy" target="new">Privacy Policy</a>.
                  </label>
                </div>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button type="submit" id="kt_login_signup_submit" class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4">Submit</button>
                <button id="googleSignIn" type="button" class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg">
                  <span class="svg-icon svg-icon-md">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M19.9895 10.1871C19.9895 9.36767 19.9214 8.76973 19.7742 8.14966H10.1992V11.848H15.8195C15.7062 12.7671 15.0943 14.1512 13.7346 15.0813L13.7155 15.2051L16.7429 17.4969L16.9527 17.5174C18.879 15.7789 19.9895 13.221 19.9895 10.1871Z" fill="#4285F4" />
                      <path d="M10.1993 19.9313C12.9527 19.9313 15.2643 19.0454 16.9527 17.5174L13.7346 15.0813C12.8734 15.6682 11.7176 16.0779 10.1993 16.0779C7.50243 16.0779 5.21352 14.3395 4.39759 11.9366L4.27799 11.9466L1.13003 14.3273L1.08887 14.4391C2.76588 17.6945 6.21061 19.9313 10.1993 19.9313Z" fill="#34A853" />
                      <path d="M4.39748 11.9366C4.18219 11.3166 4.05759 10.6521 4.05759 9.96565C4.05759 9.27909 4.18219 8.61473 4.38615 7.99466L4.38045 7.8626L1.19304 5.44366L1.08875 5.49214C0.397576 6.84305 0.000976562 8.36008 0.000976562 9.96565C0.000976562 11.5712 0.397576 13.0882 1.08875 14.4391L4.39748 11.9366Z" fill="#FBBC05" />
                      <path d="M10.1993 3.85336C12.1142 3.85336 13.406 4.66168 14.1425 5.33717L17.0207 2.59107C15.253 0.985496 12.9527 0 10.1993 0C6.2106 0 2.76588 2.23672 1.08887 5.49214L4.38626 7.99466C5.21352 5.59183 7.50242 3.85336 10.1993 3.85336Z" fill="#EB4335" />
                    </svg>
                  </span>Sign in with Google</button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="preview pt-25 pb-10 text-center">
            <img :src="require('@/assets/media/misc/reports.png')">
            <h4 class="mt-5">Watch your investment grow</h4>
            <p class="text-muted font-weight-bold">Our accurate reports are based on real market data.</p>
          </div>
        </div>
      </div>
    </div>
    <LoginFooter></LoginFooter>
  </div>
</template>

<script>
 import '../keen/sass/pages/login/login-2.scss'
 import { fetchJSON } from '../auth'
 import LoginHeader from '../components/LoginHeader.vue'
 import LoginFooter from '../components/LoginFooter.vue'

 function convert(userID) {
   window.heap.identify(userID)
   window.heap.track('signup')
   window.gtag('event', 'conversion', {'send_to': 'AW-975723438/dVO5CMPkr_wBEK63odED'})
 }

 export default {
   name: 'Register',
   components: { LoginHeader, LoginFooter },
   data() {
     return {
       name: '',
       email: this.$route.query.email,
       password: '',
       confirm: '',
       error: '',
     }
   },
   mounted() {
     let that = this
     this.$nextTick(function () {
       let element = document.getElementById('googleSignIn');
       window.gapi.load('auth2', function(){
         let auth2 = window.gapi.auth2.init({
           client_id: '67499962843-02cha5gh04riab20mmjeu1k7732n66pm.apps.googleusercontent.com',
           cookiepolicy: 'single_host_origin',
           fetch_basic_profile: true
         });
         auth2.attachClickHandler(
           element, {},
           function(googleUser) {
             var token = googleUser.getAuthResponse().id_token;
             fetchJSON('/sessions/google', {
               method: 'POST',
               body: JSON.stringify({token: token}),
             }).then((data) => {
               that.googleAuthComplete(data)
             }).catch(error => that.error = error)
           }, function(error) {
             console.log('Sign-in error', error);
           }
         );
       });
     })
   },
   methods: {
     googleAuthComplete(data) {
       window.heap.track('google-login')
       localStorage.setItem('session', JSON.stringify(data.session))
       if (data.is_new) {
         convert(data.user_id)
       }
       fetchJSON('/users/me').then(data => {
         localStorage.setItem('user', JSON.stringify(data.user))
         this.$router.push('/properties')
       }).catch(error => this.error = error)
     },

     submit() {
       fetchJSON('/users', {
         method: 'POST',
         body: JSON.stringify({
           user: {
             email: this.email,
             password: this.password,
             name: this.name,
           },
         })
       }).then((user) => {
         localStorage.setItem('user', JSON.stringify(user))
         convert(user.id)
         return fetchJSON('/sessions', {
           method: 'POST',
           body: JSON.stringify({email: this.email, password: this.password })
         })
       }).then((data) => {
         localStorage.setItem('session', JSON.stringify(data))
         this.$router.push('/properties')
       }).catch(error => this.error = error)
     }
   }
 }
</script>

<style scoped>
 .login-signup {
   display: block !important;
 }
 .preview {
   transform: perspective(75em) rotateY(-10deg);
 }
 .preview img {
   width: 90%;
   border: 1px solid #ddd;
   box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1)
 }
 @media (max-width: 1024px) {
   .preview {
     margin: 0 5rem;
     transform: none;
   }
 }
</style>
