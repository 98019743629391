<template>
  <div class="login login-2 login-signin-on d-flex flex-column flex-column-fluid bg-white position-relative overflow-hidden" id="kt_login">
    <LoginHeader page="register"></LoginHeader>

    <div class="login-body d-flex flex-column-fluid align-items-stretch justify-content-center">

      <div class="container row">
        <div class="col-12">
          <div v-if="error.length > 0" class="alert alert-danger">
            <b>Problem</b>: {{error}}.
          </div>
          <div v-if="emailSent" class="alert alert-success">
            A confirmation email has been sent to <b>{{email}}</b>. If you don't see it, please check your spam folder.
          </div>
          <div class="card card-custom gutter-b">
            <div class="card-header border-0 pt-6">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder font-size-h4 text-dark-75">Confirm Email</span>
                <span class="text-muted mt-3 font-weight-bold font-size-lg">Please confirm your email.</span>
              </h3>
            </div>
            <div class="card-body pt-3">
              <p>
                We've sent a confirmation email to <b>{{email}}</b>. Please confirm your email address by clicking the secure link contained in that email.
              </p>
              <p>If you haven't received an email, you can click the button below to resend one.</p>
              <div class="mt-6">
                <button @click.prevent="send" class="btn btn-primary">Resend</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoginFooter></LoginFooter>
  </div>
</template>

<script>
 import '../keen/sass/pages/login/login-2.scss'
 import LoginHeader from '../components/LoginHeader.vue'
 import LoginFooter from '../components/LoginFooter.vue'
 import { fetchJSON, getUser } from '../auth'

 export default {
   name: 'ConfirmEmail',
   components: { LoginHeader, LoginFooter },
   data() {
     return {
       emailSent: false,
       email: '',
       error: '',
     }
   },
   mounted() {
     const user = getUser()
     this.email = user.email
   },
   methods: {
     send() {
       const user = getUser()
       const url = `/users/send-confirm`

       this.error = ''
       this.email = user.email

       fetchJSON(url, {
         method: 'POST',
       }).then(() => {
         this.emailSent = true
       }).catch(error => {
         this.error = error
       })
     }
   },
 }
</script>

<style scoped>
 .card.card-custom {
   box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 10%);
 }
</style>
