function fetchJSON(path, opts) {
  const session = getSession()

  opts ||= { method: 'GET' }
  opts.headers ||= {}
  opts.headers['Content-Type'] = 'application/json'

  if (session) {
    opts.headers['Auth-Token'] = session.token
  }

  return fetch(process.env.VUE_APP_API_URL + path, opts).then(res => {
    return new Promise((resolve, reject) => {
      res.json().then((json) => resolve({
        status: res.status,
        json,
      })).catch(error => {
        reject(error)
      })
    })
  }).then((data) => {
    if (data.status != 200) {
      throw data.json.error
    }
    return data.json
  })
}

function getSession() {
  const data = localStorage.getItem('session')
  if (!data) {
    return null
  }
  return JSON.parse(data)
}

function getUser() {
  const data = localStorage.getItem('user')
  if (!data) {
    return null
  }
  return JSON.parse(data)
}

export { fetchJSON, getSession, getUser }
