<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 pt-6">
      <h4 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder font-size-h4 text-dark-75">Guide</span>
      </h4>
    </div>
    <div class="card-body pt-0 font-size-lg">
      <p class="text-muted mb-8">Enter your <b>address</b> to and the form will auto-fill the location information for you.</p>
      <p class="text-muted mb-8">For <b>basic information</b>, enter it as accurately as possible. You can come back and edit the information later if you don't know the exact values.</p>
      <p class="text-muted mb-8"><b>Quality</b> is meant to capture the overall level of "luxury" of the property. Leave it in the middle if your home is average. Higher if the property has high quality fixtures or luxury finishings.</p>
      <p class="text-muted mb-8">Check off all of the <b>features</b> your property has. They will affect your home's value.</p>
      <p class="text-muted mb-8"><router-link to="/contact">Contact us</router-link> if you need any more help.</p>
    </div>
  </div>
</template>

<script>
 export default {
   name: 'EditGuide',
 }
</script>
