<template>
  <div>
    <div class="d-flex flex-row flex-column-fluid page">
      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
        <Nav selected="properties"></Nav>
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <div class="d-flex flex-column-fluid">
            <div class="container">
              <div class="row">
                <div class="col-xxl-4">
                  <div class="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0 pt-6">
                      <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder font-size-h4 text-dark-75">All Properties</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-lg">You've added {{ properties.length }} properties.</span>
                      </h3>
                    </div>
                    <div class="card-body pt-3">
                      <div v-for="prop in properties" v-bind:key="prop.id" class="d-flex align-items-center mb-2 rounded px-4 py-3" v-bind:class="{ 'selected': prop.id == selected.id }">
                        <div class="symbol symbol-35 flex-shrink-0 mr-3">
                          <img alt="Pic" :src="'https://homeinsights-uploads.imgix.net/' + (prop.image_url || 'img/generic-home.png') + '?w=35&h=35&fit=crop&crop=entropy'">
                        </div>
                        <div class="d-flex flex-wrap flex-row-fluid">
                          <div class="d-flex flex-column pr-5 flex-grow-1">
                            <a href="#" @click.prevent="selectProperty(prop.id)" class="text-dark text-hover-primary mb-1 font-weight-bolder font-size-lg">{{ prop.address }}</a>
                            <span class="text-muted font-weight-bold">{{ prop.city }}, {{ prop.province }}</span>
                          </div>
                          <div class="d-flex align-items-center py-2">
                            <span class="item-growth text-success font-weight-bolder font-size-sm pr-6">+0%</span>
                            <a href="#" @click.prevent="selectProperty(prop.id)" class="btn btn-icon btn-light btn-sm">
                              <span class="svg-icon svg-icon-md svg-icon-success">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                    <path d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999)"></path>
                                  </g>
                                </svg>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="properties.length == 0" class="col-xxl-8">
                  <div class="card card-custom card-stretch gutter-b">
                    <div class="card-body">
                      <p class="text-muted font-style-bold font-size-lg text-center" style="margin-top: 1.8rem;">No properties added yet. Add one!</p>
                    </div>
                  </div>
                </div>
                <div v-if="properties.length > 0" class="col-xxl-8">
                  <div class="card card-custom gutter-b">
                    <div v-if="properties.length > 0" class="card-header border-0 pt-6">
                      <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder font-size-h4 text-dark-75">{{ selected.address }}</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-lg">{{ selected.city }}, {{ selected.province }}, {{ selected.postal_code }}</span>
                      </h3>
                      <div class="mt-3">
                        <router-link :to="`/properties/${selected.id}/edit`" class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"></path>
                                <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                              </g>
                            </svg>
                            <!--end::Svg Icon-->
                          </span>
                        </router-link>
                        <a href="#" @click.prevent="deleteProperty()" class="btn btn-icon btn-light btn-hover-primary btn-sm">
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <!--begin::Svg Icon | path:/keen/theme/demo3/dist/assets/media/svg/icons/General/Trash.svg-->
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fill-rule="nonzero"></path>
                                <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"></path>
                              </g>
                            </svg>
                            <!--end::Svg Icon-->
                          </span>
                        </a>
                      </div>
                    </div>
                    <div v-if="properties.length > 0" class="card-body pt-5">
                      <div class="pb-3 row">
                        <div class="col-md-8">
                          <img class="main-photo" :src="'https://homeinsights-uploads.imgix.net/' + (selected.image_url || 'img/generic-home.png') + '?w=520&h=293&fit=crop&crop=entropy'">
                        </div>
                        <div class="col-md-4">
                          <div class="map"></div>
                        </div>
                      </div>
                      <hr>
                      <div class="row mt-5">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-6 col-md-4">
                              <ul class="mt-4 font-size-lg text-muted">
                                <li>Built: <b>{{ selected.year_built }}</b></li>
                                <li>Square Footage: <b>{{ selected.area }}</b></li>
                                <li>Land Size: <b>{{ selected.land_area }}</b></li>
                              </ul>
                            </div>
                            <div class="col-6 col-md-4">
                              <ul class="mt-4 font-size-lg text-muted">
                                <li>Bedrooms: <b>{{ selected.bedrooms }}</b></li>
                                <li>Bathrooms: <b>{{ selected.bathrooms }}</b></li>
                                <li>Quality: <b>{{ formatQuality(selected.quality) }}</b></li>
                              </ul>
                            </div>
                            <div class="col-6 col-md-4">
                              <ul class="mt-4 font-size-lg text-muted">
                                <li>Pool: <b>{{ formatBool(selected.has_pool) }}</b></li>
                                <li>Garage: <b>{{ formatBool(selected.has_garage) }}</b></li>
                                <li>Separate Suite: <b>{{ formatBool(selected.has_rental_unit) }}</b></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div v-if="properties.length == 0" class="card-body d-flex" style="height: 200px;">
                      <p class="text-muted text-center font-weight-bold font-size-lg align-self-center" style="width:100%">No properties yet. Add one!</p>
                    </div>
                  </div>

                  <div class="card card-custom gutter-b">
                    <div class="card-header border-0 pt-6">
                      <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder font-size-h4 text-dark-75">Evaluations</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-lg">Property value assessments.</span>
                      </h3>
                    </div>
                    <div class="card-body pt-5">
                      <div class="row">
                        <div v-if="hasSubscription" class="col-12">
                          <p v-if="!hasReports">We're still crunching numbers. Check back soon.</p>
                          <table v-else class="table">
                            <thead>
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Assessed Value</th>
                                <th scope="col">Growth</th>
                                <th scope="col">Comparable Properties</th>
                                <th scope="col">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-for="report in reportsForProperty" v-bind:key="report.id">
                                <tr>
                                  <th scope="row">{{ formatDate(new Date(report.created_at)) }}</th>
                                  <td><span class="font-weight-bold" v-bind:class="{'text-primary': report.growth > 0, 'text-danger': report.growth < 0}">{{ formatMoney(report.value) }}</span></td>
                                  <td class="font-weight-bold" v-bind:class="{'text-primary': report.growth > 0, 'text-danger': report.growth < 0}">{{ formatPercent(report.growth) }}</td>
                                  <td class="text-muted"><a href="#" @click.prevent="toggleReport(report.id)">View properties ({{ report.comparables.length }})</a></td>
                                  <td><button class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3" @click.prevent="toggleReport(report.id)">&gt;</button></td>
                                </tr>
                                <tr v-if="reportExpanded(report.id)">
                                  <td class="report-expand p-5" colspan="5">
                                    <div class="row">
                                      <div v-for="mls in report.comparables" v-bind:key="mls" class="col-sm-6 col-md-4 mt-5">
                                        <div class="card card-custom">
                                          <img class="card-img-top" :src="'https://homeinsights-repliers.imgix.net/' + listingImage(reportListings, mls) + '?w=300&h=200&fit=crop&crop=entropy'">
                                          <div class="card-body pt-6 pb-5">
                                            <h6>{{ reportListings[mls].address.streetNumber }} {{ reportListings[mls].address.streetName }}</h6>
                                            <p class="text-muted">{{ reportListings[mls].address.city }}, BC</p>
                                            <p>Sold for <b>{{ formatMoney(reportListings[mls].soldPrice) }}</b>.</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                        <div v-else class="col-12">
                          <p>Create a subscription to unlock this feature.</p>
                          <router-link class="btn btn-primary mt-3" to="/settings/payment">Subscribe</router-link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card card-custom gutter-b">
                    <div class="card-header border-0 pt-6">
                      <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder font-size-h4 text-dark-75">Growth</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-lg">Property value over time.</span>
                      </h3>
                    </div>
                    <div class="card-body pt-5">
                      <div class="row">
                        <div v-if="hasSubscription" class="col-12">
                          <div id="chart"></div>
                        </div>
                        <div v-else class="col-12">
                          <p>Create a subscription to unlock this feature.</p>
                          <router-link class="btn btn-primary mt-3" to="/settings/payment">Subscribe</router-link>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
 import Nav from '../components/Nav.vue'
 import Footer from '../components/Footer.vue'
 import ApexCharts from 'apexcharts'
 import { fetchJSON } from '../auth'
 import { formatBool, formatQuality, formatDate, formatMoney, formatPercent } from '../format'
 import Swal from 'sweetalert2'
 import moment from 'moment'

 export default {
   name: 'Properties',
   components: { Nav, Footer },
   data() {
     return {
       error: '',
       properties: [],
       reports: [],
       selected: {},
       paymentDetails: {},
       expandedReports: {},
       reportListings: {},
     }
   },
   computed: {
     reportsForProperty() {
       return this.reports.filter((r) => {
         return r.property_id == this.selected.id
       })
     },
     hasSubscription() {
       return this.paymentDetails.stripe_subscription_status == 'active'
     },
     hasReports() {
       return this.reports.length > 0
     },
   },
   methods: {
     formatBool,
     formatQuality,
     formatDate,
     formatMoney,
     formatPercent,
     listingImage(reportListings, mls) {
       const def = 'img/generic-home.png'
       if (!reportListings[mls]) {
         return def
       }
       let images = reportListings[mls].images
       if (!images || images.length < 1) {
         return def
       }
       return images[0]
     },
     reportExpanded(reportId) {
       return this.expandedReports[reportId] == true
     },
     toggleReport(reportId) {
       if (!this.expandedReports[reportId]) {
         this.expandedReports[reportId] = true
       } else {
         this.expandedReports[reportId] = false
       }
     },
     loadProperties() {
       fetchJSON('/properties').then(data => {
         this.properties = data.properties
         this.selectProperty(this.properties[0].id)
       }).catch(error => this.error = error)
     },
     loadReports() {
       return fetchJSON('/reports').then(data => {
         this.reports = data.reports
         this.reportListings = data.listings
       }).catch(error => this.error = error)
     },
     selectProperty(id) {
       this.selected = this.properties.find((p) => { return p.id == id })
       // TODO: use a component for the map
       this.$nextTick(() => {
         this.renderMap()
       })
     },
     // TODO: use components for the graph
     loadSubAndReports() {
       fetchJSON('/subscription').then(data => {
         this.paymentDetails = data.payment_details
       }).then(() => {
         return this.loadReports()
       }).then(() => {
         this.renderGraph()
       }).catch(error => this.error = error)
     },
     deleteProperty() {
       Swal.fire({
         title: 'Are you sure?',
         text: 'You will permanently lose this property.',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Yes, delete it!',
       }).then((result) => {
         if (!result.isConfirmed) return;
         fetchJSON(`/properties/${this.selected.id}`, {
           method: 'DELETE'
         }).then(() => {
           this.selected = {}
         }).catch(error => this.error = error)
       })
     },
     renderMap() {
       const coords = {
         lat: this.selected.latitude,
         lng: this.selected.longitude,
       }

       var map = new window.google.maps.Map(document.getElementsByClassName('map')[0], {
         center: coords,
         zoom: 15,
         gestureHandling: 'none',
         disableDefaultUI: true,
       });

       new window.google.maps.Marker({
         position: coords,
         map: map,
         title: 'Property'
       });
     },
     renderGraph() {
       const primary = '#6993FF';

       let data = []
       let months = []

       this.reportsForProperty.slice().reverse().forEach((report) => {
         data.push(report.value)
         months.push(moment(report.created_at).format('MMM Do'))
       })

       var options = {
         series: [{
           name: "Estimates",
           data: data,
         }],
         chart: {
           height: 200,
           type: 'line',
           zoom: {
             enabled: false
           }
         },
         dataLabels: {
           enabled: false
         },
         stroke: {
           curve: 'straight'
         },
         grid: {
           row: {
             colors: ['#f3f3f3', 'transparent'],
             opacity: 0.5
           },
         },
         xaxis: {
           categories: months,
         },
         colors: [primary]
       };

       let el = document.querySelector('#chart')
       if (el) {
         el.innerHTML = ''
         var chart = new ApexCharts(el, options);
         chart.render();
       }
     }
   },
   mounted() {
     this.loadProperties()
     this.loadSubAndReports()
   }
 }
</script>

<style scoped>
 .map {
   width: 100%;
   height: 100%;
   min-height: 190px;
 }
 .main-photo {
   width: 100%;
 }
 .selected {
   background: #f6f6f6;
 }
 .report-price {
   font-size: 1rem;
 }
 table td, table th {
   vertical-align: middle;
 }
 .report-expand {
   overflow-x: auto;
   background: #f6f6f6;
   box-shadow: inset 0 10px 10px rgba(0, 0, 0, 0.05);
   padding-top: 0 !important;
 }
 @media (max-width: 760px) {
   .main-photo {
     margin-bottom: 1rem;
   }
 }
</style>
