<template>
  <div>
    <div class="d-flex flex-row flex-column-fluid page">
      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
        <Nav selected="settings"></Nav>
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <div class="d-flex flex-column-fluid">
            <div class="container">
              <div v-if="error.length > 0" class="alert alert-danger">
                <b>Problem</b>: {{error}}.
              </div>
              <div v-if="success" class="alert alert-success">
                Settings saved successfully.
              </div>
              <div class="row">
                <div class="col-xl-3 col-xxl-3">
                  <SettingsNav selected="settings"></SettingsNav>
                </div>
                <div class="col-xl-9 col-xxl-9">

                  <div class="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0 pt-6">
                      <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder font-size-h4 text-dark-75">Account Settings</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-lg">Edit your account settings.</span>
                      </h3>
                    </div>
                    <div class="card-body pt-5">

                      <form @submit.prevent="submitForm">
                        <h6>Notifications</h6>

                        <hr>

                        <div class="checkbox-inline m-5">
                          <label class="checkbox checkbox-success">
                            <input v-model="settings.report_emails" type="checkbox">
                            <span></span>Send me email notifications for every assessment.
                          </label>
                        </div>

                        <div class="checkbox-inline m-5">
                          <label class="checkbox checkbox-success">
                            <input v-model="settings.marketing_emails" type="checkbox">
                            <span></span>Send me emails about product enhancements.
                          </label>
                        </div>

                        <div>
                          <button type="submit" class="btn btn-primary">Save</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
 import Nav from '../components/Nav.vue'
 import SettingsNav from '../components/SettingsNav.vue'
 import Footer from '../components/Footer.vue'
 import { fetchJSON } from '../auth'

 export default {
   name: 'AccountSettings',
   components: { Nav, SettingsNav, Footer },

   data() {
     return {
       error: '',
       success: false,
       settings: {},
     }
   },

   methods: {
     loadSettings() {
       fetchJSON(`/settings`, {
         method: 'GET',
       }).then(data => {
         this.settings = data.settings
       }).catch(error => {
         this.error = error
       })
     },
     submitForm() {
       fetchJSON(`/settings`, {
         method: 'PUT',
         body: JSON.stringify({ settings: this.settings }),
       }).then(() => {
         this.success = true
       }).catch(error => {
         this.error = error
       })
     }
   },

   mounted() {
     this.loadSettings()
   }
 }
</script>

<style lang="scss">
 .card-wrapper {
   max-width: 550px;
 }
</style>
