<template>
  <!--begin::Login-->
  <div class="login login-2 login-signin-on d-flex flex-column flex-column-fluid bg-white position-relative overflow-hidden" id="kt_login">
    <!--begin::Header-->
    <div class="login-header py-10 flex-column-auto">
    <div class="container d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between">
      <!--begin::Logo-->
      <a href="#" class="flex-column-auto py-5 py-md-0">
      <img src="@/assets/media/logos/logo-6.svg" alt="logo" class="h-50px" />
      </a>
      <!--end::Logo-->
      <span class="text-muted font-weight-bold font-size-h4">Already a member?
        <router-link to="/login" id="kt_login_signup" class="text-primary font-weight-bolder">Login</router-link>
      </span>
    </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="login-body d-flex flex-column-fluid align-items-stretch justify-content-center">
      <div class="container row">
        <div class="col-lg-6 d-flex align-items-center">
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form class="form w-xxl-550px rounded-lg p-20" novalidate="novalidate" id="kt_login_forgot_form">
              <!--begin::Title-->
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Forgotten Password?</h3>
                <p class="text-muted font-weight-bold font-size-h4">Enter your email to reset your password</p>
              </div>
              <!--end::Title-->
              <!--begin::Form group-->
              <div class="form-group">
                <input class="form-control form-control-solid h-auto p-6 rounded-lg font-size-h6" type="email" placeholder="Email" name="email" autocomplete="off" />
              </div>
              <!--end::Form group-->
              <!--begin::Form group-->
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button type="button" id="kt_login_forgot_submit" class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4">Submit</button>
                <router-link to="/login" id="kt_login_forgot_cancel" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3">Cancel</router-link>
              </div>
              <!--end::Form group-->
            </form>
            <!--end::Form-->
          </div>
          <!--end::Forgot-->
        </div>
        <div class="col-lg-6 bgi-size-contain bgi-no-repeat bgi-position-y-center bgi-position-x-center min-h-150px mt-10 m-md-0" :style="{backgroundImage: 'url(' + require('@/assets/media/svg/illustrations/accomplishment.svg') + ')'}"></div>
      </div>
    </div>
    <!--end::Body-->
    <!--begin::Footer-->
    <div class="login-footer py-10 flex-column-auto">
      <div class="container d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between">
        <div class="font-size-h6 font-weight-bolder order-2 order-md-1 py-2 py-md-0">
          <span class="text-muted font-weight-bold mr-2">2020©</span>
          <a href="https://homeinsights.ca/" class="text-dark-50 text-hover-primary">HomeInsights</a>
        </div>
        <div class="font-size-h5 font-weight-bolder order-1 order-md-2 py-2 py-md-0">
          <a href="#" class="text-primary">Terms</a>
          <a href="#" class="text-primary ml-10">Plans</a>
          <a href="#" class="text-primary ml-10">Contact Us</a>
        </div>
      </div>
    </div>
    <!--end::Footer-->
  </div>
  <!--end::Login-->
</template>


<script lang="js">
 import '../keen/sass/pages/login/login-2.scss'

 export default {
   data() {
     return {
       email: '',
       password: '',
     }
   },
   methods: {
     submit() {
       console.log(this.email, this.password)

       fetch(`${process.env.VUE_APP_API_URL}/sessions`, {
         method: 'POST',
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({email: this.email, password: this.password })
       }).then(res => {
         res.json()
       }).then(data => {
         console.log(data)
       })
     }
   },
 }
</script>

<style lang="scss">
 .login-forgot {
   display: block !important;
 }
</style>
