import moment from 'moment'

function formatBool(bool) {
  return bool ? 'Yes' : 'No'
}

function formatQuality(dec) {
  if (dec < 0.25) {
    return "Low"
  }
  if (dec < 0.5) {
    return "Average"
  }
  if (dec < 0.75) {
    return "Above Average"
  }
  return "High"
}

function formatDate(date) {
  return moment(date).format('MMM Do YYYY')
}

function formatMoney(val) {
  return '$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function formatPercent(val) {
  if (val == 0) {
    return 'N/A'
  }

  let prefix = '+'

  if (val < 0) {
    prefix = '-'
  }

  return prefix + Math.abs(val * 100).toFixed(2) + '%'
}

export { formatBool, formatQuality, formatDate, formatMoney, formatPercent }
