<template>
  <div>
    <div class="d-flex flex-row flex-column-fluid page">
      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
        <Nav selected="settings"></Nav>
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <div class="d-flex flex-column-fluid">
            <div class="container">
              <div v-if="error.length > 0" class="alert alert-danger">
                <b>Problem</b>: {{error}}.
              </div>
              <div v-if="fromConfirm && error.length < 1" class="alert alert-success">
                Thanks for confirming your email! Complete the form below to find out your property's value.
              </div>
              <div class="row">
                <div class="col-xxl-8">
                  <div class="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0 pt-6">
                      <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder font-size-h4 text-dark-75">Add Property</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-lg">Add a new property to find out its value.</span>
                      </h3>
                    </div>
                    <div class="card-body pt-5">
                      <EditPropertyForm :property="{}" v-on:save="saveProperty"></EditPropertyForm>
                    </div>
                  </div>
                </div>
                <div class="col-xxl-4">
                  <EditGuide></EditGuide>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
 import Nav from '../components/Nav.vue'
 import EditPropertyForm from '../components/EditPropertyForm.vue'
 import Footer from '../components/Footer.vue'
 import EditGuide from '../components/EditGuide.vue'
 import { fetchJSON } from '../auth'

 export default {
   name: 'AddProperty',
   components: { Nav, EditPropertyForm, Footer, EditGuide },

   data() {
     return {
       error: '',
     }
   },

   computed: {
     fromConfirm() {
       return this.$route.query.from && this.$route.query.from == 'confirm'
     },
   },

   methods: {
     saveProperty(property) {
       fetchJSON('/properties', {
         method: 'POST',
         body: JSON.stringify({property: property})
       }).then(() => {
         window.heap.track('add_property')
         this.$router.push({ name: 'Properties' })
       }).catch(error => this.error = error)
     }
   }
 }
</script>

<style lang="scss">
</style>
