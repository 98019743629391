<template>
  <div id="kt_header" class="header header-fixed">
    <div class="container">

      <div class="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">

        <div id="kt_header_menu" class="header-menu header-menu-left header-menu-mobile header-menu-layout-default">
          <a href="/properties" class="pr-10 logo"></a>

          <ul class="menu-nav">
            <li class="menu-item" v-bind:class="{ 'menu-item-active': selected == 'properties' }">
              <router-link to="/properties" class="menu-link">
                <span class="menu-text">Properties</span>
              </router-link>
            </li>
          </ul>
          <ul class="menu-nav">
            <li class="menu-item" v-bind:class="{ 'menu-item-active': selected == 'settings' }">
              <router-link to="/settings" class="menu-link">
                <span class="menu-text">Account Settings</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="topbar">
        <div class="topbar-item">
          <router-link to="/properties/new" class="btn btn-fixed-height btn-primary font-weight-bolder font-size-sm px-6">Add Property</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 export default {
   name: 'Nav',
   props: {
     selected: {
       default: 'properties'
     }
   }
 }
</script>

<style scoped>
 .logo {
   height: 70px;
   width: 220px;
   display: block;
   background-size: cover;
   background-image: url('../assets/media/logos/logo-sm-dark.png');
 }
 @media (max-width: 991.98px) {
   .topbar {
     position: inherit;
   }
   .logo {
     margin-left: 0.7rem;
     background-image: url('../assets/media/logos/hi-logo-sm.png');
   }
   .menu-nav {
     padding: 0;
   }
 }
</style>
