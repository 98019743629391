<template>
  <router-view/>
</template>

<script>
 import './keen/sass/style.scss'
 import './keen/plugins/keenthemes-icons/font/ki.css'

 export default {
   name: 'App'
 }
</script>
