<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-body font-size-lg">
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link class="nav-link" v-bind:class="{ active: isActive('settings') }" to="/settings">
            <i class="fa fa-tools mr-4"></i> Account Settings
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" v-bind:class="{ active: isActive('payment') }" to="/settings/payment">
            <i class="fa fa-money-bill mr-4"></i> Subscription
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
 export default {
   name: 'SettingsNav',
   props: {
     selected: String
   },
   methods: {
     isActive(name) {
       return this.selected == name
     }
   }
 }
</script>

<style scoped>
 .nav-item a {
   color: #3F4254;
 }
 .nav-item a.active {
   color: #0BB783;
 }
 .nav-item a.active i {
   color: #0BB783;
 }
 i {
   font-size: 1.8rem;
 }
</style>
